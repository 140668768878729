
// Action types for setting day and date Info
export const SET_DAY_ID = 'SET_DAY_ID';
export const SET_ID = 'SET_ID';
export const SET_DATE_DAY = 'SET_DATE_DAY';
export const SET_DATE_MONTH = 'SET_DATE_MONTH';
export const SET_DATE_YEAR = 'SET_DATE_YEAR';
export const SET_TOTAL_PAYMENTS = 'SET_TOTAL_PAYMENTS';
export const SET_TARGET = 'SET_TARGET';
export const SET_RETURNED = 'SET_RETURNED';
export const SET_DELIVERED = 'SET_DELIVERED';
export const SET_USD_PAYMENTS = 'SET_USD_PAYMENTS';
export const SET_LIRA_PAYMENTS = 'SET_LIRA_PAYMENTS';
export const SET_SHIPMENT_FROM_PREV = 'SET_SHIPMENT_FROM_PREV';
export const SET_SHIPMENT_PROFITS_IN_LIRAS = 'SET_SHIPMENT_PROFITS_IN_LIRAS';
export const SET_SHIPMENT_EXPENSES_IN_LIRAS = 'SET_SHIPMENT_EXPENSES_IN_LIRAS';
export const SET_SHIPMENT_PROFITS_IN_USD = 'SET_SHIPMENT_PROFITS_IN_USD';
export const SET_SHIPMENT_EXPENSES_IN_USD = 'SET_SHIPMENT_EXPENSES_IN_USD';
export const ADD_CUSTOMER_WITH_FILLED_ORDER = 'ADD_CUSTOMER_WITH_FILLED_ORDER';
export const ADD_CUSTOMER_WITH_EMPTY_ORDER = 'ADD_CUSTOMER_WITH_EMPTY_ORDER';

// Action types for clearing day and date Info
export const CLEAR_DAY_ID = 'CLEAR_DAY_ID';
export const CLEAR_ID = 'CLEAR_ID';
export const CLEAR_SHIPMENT_PROFITS_IN_LIRAS = 'CLEAR_SHIPMENT_PROFITS_IN_LIRAS';
export const CLEAR_SHIPMENT_PROFITS_IN_USD = 'CLEAR_SHIPMENT_PROFITS_IN_USD';
export const CLEAR_SHIPMENT_EXPENSES_IN_USD = 'CLEAR_SHIPMENT_EXPENSES_IN_USD';
export const CLEAR_SHIPMENT_EXPENSES_IN_LIRAS = 'CLEAR_SHIPMENT_EXPENSES_IN_LIRAS';
export const CLEAR_TARGET = 'CLEAR_TARGET';
export const CLEAR_DATE_DAY = 'CLEAR_DATE_DAY';
export const CLEAR_DATE_MONTH = 'CLEAR_DATE_MONTH';
export const CLEAR_DATE_YEAR = 'CLEAR_DATE_YEAR';
export const CLEAR_ALL_SHIPMENT_INFO = 'CLEAR_ALL_SHIPMENT_INFO';
